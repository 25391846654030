<template>
    <div class="info-list">
        <Header/>
        <div class="main-page-content contain-left-right">
            <div class="left-content" style="width:1200px;min-height: calc(100vh - 501px);">
                <div style="text-align:center;font-size:18px;padding: 10px 0;color:#5da9e4b;font-weight:bold">合作流程</div>
                <div v-if="globalConfig.hzlc_content.content" class="content_con" v-html="globalConfig.hzlc_content.content"> 
                </div>
                <a :href="globalConfig.hzlc_table.image" target="_blank">
                    <div class="btn">{{globalConfig.hzlc_table.name}}</div>
                </a>
                
                <!-- <div v-if="globalConfig.hzlc_table" class="content_con" v-html="obj.hzlc_table"> 
                </div> -->
            </div>
            
        </div>
        <div class="blank20"/>
        <Footer/>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed: mapState([
        'globalConfig',
    ]),
    data() {
        return {
        }
    },
    components:{
    },
    async beforeMount() {
    },
    created() {
        // if(this.$route.params.id){
        //     this.type = this.$route.params.type;
        // }
    },
}
</script>
<style lang="scss" scoped>
.tit-list {
    height: 50px;
    line-height: 50px;
}
.weizhi{
    font-size: 14px;
    line-height: 50px;
    color: #224899;
    margin-left: 10px;
}
.content_header{ 
    padding:20px 0px 20px;
    h1{ font-size:22px; color:#000; height:40px; line-height:40px;}
    .header_msg{ 
        color:#999; font-size:14px; padding:5px 0 15px 0; 
        i{ margin:0 3px;}
    }
}
.weizhi a {
    font-size: 14px;
    line-height: 50px;
    padding: 0 5px;
    color: #606266;
}
a, a:link {
    cursor: pointer;
    color: #333;
    text-decoration: none;
}
a:hover{
    color: #224899;
}

.btn{
    cursor: pointer;
    width:180px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    color: #fff;
    font-weight: 700;
    color: #fff;
    margin:auto;
    margin-top: 20px;
    background: #0087F2;
}
</style>
